// Your variable overrides
$body-bg: #fff;
$body-color: #666;

$theme-colors: (
    'primary': #0c90f5,
    'danger': #e21010,
) !default;

// Bootstrap and its default variables
@import 'node_modules/bootstrap/scss/bootstrap';

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
}

html[data-page='asset-map'] {
    #scrollable-area {
        padding: 0;
        > .row {
            margin: 0;
            > .col {
                padding: 0;
            }
        }
    }
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5 {
    font-family: 'Inspire TWDC Heavy', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #000;
}

b,
strong {
    color: #111;
}
a {
    text-decoration: underline;
}

.nav-link {
    cursor: pointer;
    &.active {
        color: #0c90f5 !important;
    }
}

/* Form CSS */
.form legend {
    color: #0c90f5;
}
label.is-invalid {
    font-weight: normal !important;
    color: #dc3545 !important;
}
.is-invalid {
    .react-select__control,
    &.input-datetime .form-control,
    &.input-datetime .input-group-text {
        border-color: #dc3545 !important;
    }
    &.input-datetime .form-control {
        border-right: none;
    }
    &.input-select,
    &.input-datetime {
        padding-right: 2rem;
        position: relative;
        &:after {
            content: '';
            position: absolute;
            top: 0.4rem;
            right: 0;
            width: 1.6rem;
            height: 1.6rem;
            background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
            background-repeat: no-repeat;
            background-position: right calc(0.375em + 0.1875rem) center;
            background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
        }
    }
}

.react-select__group {
    .react-select__option {
        font-size: 95%;
    }
    .react-select__group-heading {
        font-size: 100% !important;
        color: #0c90f5 !important;
    }
}

.no-outline,
.no-outline:active {
    outline: none !important;
}

button[disabled] {
    cursor: not-allowed;
}

.react-datepicker-wrapper {
    display: inline-flex;
    width: 242px;
}

.form-check-input {
    position: relative;
    margin: -3px 0 0 -18px;
}

button {
    svg + span,
    span + svg {
        margin-left: 0.5rem;
    }
}
.dropdown-toggle:after {
    margin-left: 0.25rem;
}

label {
    color: #000;
}

.container {
    max-width: 1560px;
}

html[data-page='home'] body {
    background-color: #ebebeb;
}

.card-body {
    padding: 1rem;
}

.react-datepicker-popper {
    z-index: 2;
}

.documents {
    .doc-header {
        border: solid 1px black;
        border-left: none;
        border-right: none;
        padding: 0.5rem 0;
        font-size: 13px;
    }
    .doc-row {
        padding: 0.5rem 0;
        border-top: solid 1px #dee2e6;
    }
    .doc-item {
        padding: 1rem 0;
    }
    .doc-row + .doc-item {
        border-bottom: solid 1px #dee2e6;
    }
    .doc-document {
        &:hover,
        &.opened {
            background-color: #c3e1fc;
        }
    }
    .doc-item {
        background-color: #f5f5f5;
    }

    #doc-designelement-col:before,
    #doc-dmcname-col:before,
    #doc-artstyle-col:before,
    #doc-contenttype-col:before,
    #doc-characters-col:before,
    #doc-keywords-col:before,
    #doc-holiday-col:before,
    #doc-language-col:before {
        content: ' ';
        background-color: grey;
        position: absolute;
        right: 0;
        width: 4px;
        height: 100%;
        cursor: col-resize;
    }

    .doc-designelement .doc-dmcname,
    .doc-artstyle,
    .doc-contenttype,
    .doc-characters,
    .doc-keywords,
    .doc-holiday,
    .doc-language {
        font-size: 13px;
    }
}

.clickable {
    cursor: pointer;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
}
.sticky:before,
.sticky:after {
    content: '';
    display: table;
}

.read-more-button {
    display: inline-block;
    margin-left: 0.5rem;
    color: $primary;
    cursor: pointer;
}
